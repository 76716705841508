.box {
padding: 80px 60px;
background: black;
/* position: absolute; */
bottom: 0;
height : 20%;
width: 100%;
/* position: fixed; */
}

@media (max-width: 1000px) {
	.box {
        padding: 70px 30px;
    }
    .column {
        margin-left: 0px !important;
    }
    /* .footerRow {
        grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
    } */
}

.container {
    display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
    
font-size: 12px;
	/* background: red; */
}

.column {
    display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px;

font-size: 12px;
}

.footerRow { 
    display: grid;
grid-template-columns: auto auto auto;
grid-gap: 5px;

font-size: 12px;
}

.footerLink {
    color: #fff;
margin-bottom: 2px;
font-size: 12px;
text-decoration: none;

}

.footerLink:hover {
	color: rgb(15, 15, 15);
	transition: 200ms ease-in;
}

.heading {
    font-size: 14px;
color: #fff;
margin-bottom: 5px;
font-weight: bold;
}