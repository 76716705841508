.landingPageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-style: italic;
}

.quotation {
    margin: 50px;
    padding: 50px;
    width: 50%;
    font-size: 24px;
    box-shadow: rgba(51, 51, 51, 0.3) 0px 3px 5px 1px;
}

.subscribtionBox{
    padding: 0 25px;
    position: relative;
    border: 1px solid black;
    background-color: beige;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subscribeBtn{
    border: 1px solid black;
    margin: 20px;
    padding: 10px;
    background-color: black;
    color: whitesmoke;
}

.subscribeText {
    font-size: 21px;
    line-height: 1.43;
    text-align: left;
    color:black;
    font-weight: 400;
}

.shareBar {
    position: fixed;
    top: 300px;
    right: 0;
    z-index:20;
}
.shareLink {
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    color: whitesmoke;
    text-decoration: none;
}
.bg--facebook{
    background: #3b5b94!important;
}

.bg--twitter {
    background: #3baef0!important;
}

.bg--linkedin {
    background: #0079b7!important;
}

.bg--email {
    background: #7d7d7d!important;
}