button:disabled {
    cursor: not-allowed;
  }
  
  .text-danger {
    font-weight: 500;
  }
  
  .navbar {
    height:"110px";
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    line-height: inherit;
    white-space: nowrap;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
      font-weight: bold !important;
      color: white !important;
      font-size: 22px !important;
  }
  
  .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .navbar-brand {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-weight: bold !important;
    color: white !important;
    font-size: 22px !important;
  }
  
  .bg-custom {
    background-color: black !important;
    color: white !important;
  }
  
  .nav-color {
    color: white;
  }
  
  .nav-item {
    color: white !important;
  }
  
  .nav-color:hover {
    color: rgb(8, 8, 8);
    background: #838586;
  }
  
  a:focus,
  a:hover {
    color: rgb(8, 8, 8);
    background: #838586;
  }
  
  .nav-link {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 18px !important;
    color: whitesmoke !important;
  }
  
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(8, 8, 8);
    background: #838586;
  }
  
  .card {
    opacity: 0.93;
    font-weight: bold;
  }
  
  .message {
    padding: 3px !important;
    margin-left: 15px !important;
    font-weight: 500 !important;
  }
  
  td {
    font-weight: normal !important;
    text-align: center !important;  
    vertical-align: middle !important;
  }
  
  .view {
    margin: 10px !important;
  }
  
  th {
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  
  .card-header{
    background-color:rgba(102, 119, 216, 0.89);
  }
  
  .bckgnd2{
    background-color: aliceblue;
  }
  /* 
  .blend{
    background-color: rgba(169, 169, 169, 0.623); 
  } */
  
  .bg{
    /* background:whitesmoke url("./assets/uilogo.png") no-repeat center; */
    background-blend-mode:multiply;
  }
  
  .pos{
    vertical-align: middle !important;
  }
  
  @keyframes loading {
    0%{
        --webkit-transform: scale(1);
        font-weight:lighter;
        color: transparent;
    }
    50%{
        --webkit-transform: scale(1.2);
        font-weight: 700;
        color:blue
    }
    100%{
        --webkit-transform: scale(1);
        font-weight: lighter;
        color: transparent;
    }
  }
  
  #loading{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation-name: loading;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  
  
  
  .adminActions {
    position: fixed;
    bottom: 35px; right: 35px;
  }
  
    .adminButton {
      height: 70px;
      width: 70px;
      background-color: lightseagreen;
      border-radius: 50%;
      display: block;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 1;
    }
  
      .adminButton i {
        font-size: 22px;
      }
  
    .adminButtons {
      position: absolute;
      width: 100%;
      bottom: 120%;
      text-align: center;
    }
  
      .adminButtons a {
        display: block;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        text-decoration: none;
        margin: 10px auto 0;
        line-height: 1.15;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        position: relative;
        box-shadow: 0 0 5px 1px rgba(51, 51, 51, .3);
      }
  
        .adminButtons a:hover {
          transform: scale(1.05);
        }
  
        .adminButtons a:nth-child(1) {background-color: #3747dad7; transition: opacity .2s ease-in-out .3s, transform .15s ease-in-out;}
        .adminButtons a:nth-child(2) {background-color: #eb1d1db9; transition: opacity .2s ease-in-out .25s, transform .15s ease-in-out;}
       
  
        .adminActions a i {
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
        }
  
        .adminActions a img {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
          }
  
  
  
    .adminToggle {
      -webkit-appearance: none;
      position: absolute;
      border-radius: 50%;
      top: 0; left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      z-index: 2;
      transition: box-shadow .2s ease-in-out;
      box-shadow: 0 3px 5px 1px rgba(51, 51, 51, .3);
    }
  
      .adminToggle:hover {
        box-shadow: 0 3px 6px 2px rgba(51, 51, 51, .3);
      }
  
      .adminToggle:checked ~ .adminButtons a {
        opacity: 1;
        visibility: visible;
      }
  
  #alert{
    position:fixed;
  top:40px;
    right:40%;
    border-radius:10px;
    z-index: 1;
  }
  
  .cen{
  vertical-align: middle !important;
  }

  .loginImg{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    margin: 10px;
  }